import apiBase from './ApiBase'
import moment from 'moment'
import Sentry from './../../../modules/SantryIO'
import { config } from '../../../Config'
import authClient from '../../../modules/oktaAuth'

const todayMinus30days = moment().subtract(1, 'month')
const todayMinus30daysInUTC = moment.utc(todayMinus30days).format()
const accessToken = authClient.getAccessToken()


class ApiService {
    getAirports = () => apiBase.getWithCache('/airport/GetAirports')
    updateUserSettings = (data) => apiBase.post('/Okta/UpdateUserSettings', data)
    resetPassword = () => apiBase.post('/Okta/ResetPassword')
    getUserPermissionList = () => apiBase.get('/Okta/GetUserPermissionList')
    submitFeedback = (data) => apiBase.post('/contact/Submit', data)
    submitReport = (data) => apiBase.post('/contact/Submit', data)
    submitRequestInfo = (data) => apiBase.post('/contact/SubmitRequestInfo', data)
    getInternationalSOSAlerts = (utcStartDate, toDateUtc, simplified, pageSize = 1000) => new Promise((resolve, reject) => {
        apiBase.get('/InternationalSos/alerts/data/' +
            (!!simplified ? 'headlines' : 'full') +
            '?pageSize=' + pageSize + '&status=' + (!!toDateUtc ? 'Archived' : 'All') +
            '&startActive=' + utcStartDate +
            (!!toDateUtc ? `&endActive=${toDateUtc}` : '')).then(alertsResponse => {

                resolve(alertsResponse.Alerts)
        }).catch(error => {
            Sentry.captureException(error)
            reject(error)
        })
    })
    getInternationalSOSAlertsLimited = (utcStartDate, toDateUtc, countries) => new Promise((resolve, reject) => {
        apiBase.get('/IntSosAlert/GetInternationalSosAlerts' +
            '?activeFrom=' + utcStartDate +
            '&countryIso=' + countries +
            (!!toDateUtc ? `&activeTo=${toDateUtc}` : '')).then(alertsResponse => {

                resolve(alertsResponse)
        }).catch(error => {
            Sentry.captureException(error)
            reject(error)
        })
    })
    getInternationalSOSAlertsByIDs = (alertIDs) => apiBase.post('/IntSosAlert/getInternationalSOSAlertsByIDs', alertIDs)
    getPublicAlertDetails = (alertID, clientID) => apiBase.get('/IntSosAlert/GetAlertDetails?alertId=' + alertID + '&clientId=' + clientID)
    getClientDetails = () => apiBase.get('/client/GetClientDetails')
    updateClientSettings = (data) => apiBase.post('/client/UpdateClientSettings', data)
    getOrganisationTails = () => apiBase.get('/Tails/GetTails')
    uploadOrganisationTails = (data) => apiBase.post('/Tails/AddTails', data)
    deleteOrganisationTail = (tailID) => apiBase.delete('/Tails/DeleteTail/' + tailID)
    ProcessInternationalSosAlerts = () => apiBase.get('/InternationalSos/ProcessInternationalSosAlerts')
    getCriticalAlerts = (alertId) => apiBase.get('/IntSosAlert/getCriticalAlerts?alertId=' + alertId)
    addCrew = (data) => apiBase.post('/crew/AddCrew', data)
    updateCrew = (data) => apiBase.post('/crew/UpdateCrew', data)
    assignCrew = (data) => apiBase.post('/crew/AssignCrew', data)
    deleteCrew = (crewID) => apiBase.delete('/crew/DeleteCrew/' + crewID)
    getClientCrew = () => apiBase.get('/crew/GetClientCrew')
    unassignCrew = (alertID, crewID) => apiBase.delete('/crew/UnassignCrew/' + alertID + '/' + crewID)
    updateCrewStatus = (alertID, crewID, statusString) => apiBase.put(`/crew/UpdateCrewStatus/${alertID}/${crewID}/${statusString}`)
    updateBroadCastCrewStatus = (broadcastID, alertID, crewID, statusString) => apiBase.put(`/crew/UpdateBroadCastCrewStatus/${broadcastID}/${alertID}/${crewID}/${statusString}`)
    getCriticalContacts = () => apiBase.get('/client/GetCriticalContacts')
    addCriticalContact = (data) => apiBase.post('/client/AddCriticalContacts', data)
    checkInCrew = (data) => apiBase.post('/CrewResponse/CheckIn', data)
    deleteCriticalContact = (contactID) => apiBase.delete('/client/DeleteCriticalContacts/' + contactID)
    setCriticalAlertStatus = (data) => apiBase.post('/IntSosAlert/UpdateAlertStatus', data)
    deleteArchivedCrewCheckAlert = (alertID) => apiBase.delete('/IntSosAlert/DeleteClientAlert/' + alertID)
    referToMedAire = (data) => apiBase.post('/crew/ReferToMedAire', data)
    calledMedaire = (data) => apiBase.post('/crew/CalledMedaire', data)
    updateTailStatus = (alertID, tailID, statusString) => apiBase.put(`/Tails/UpdateTailStatus/${alertID}/${tailID}/${statusString}`)
    sendMessageToCrew = (data) => apiBase.post('/message/broadcast', data)
    updateAdvisory = (data) => apiBase.post('/message/UpdateAdvisory', data)
    retryMessage = (data) => apiBase.post('/message/RetryMessage', data)
    retryEmail = (data) => apiBase.post('/message/RetryEmail', data)
    getCountryGuideCityInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideCityInfo/' + countryCode)
    //-----------------
    GetCountryGuideMedicalReferralsMedicalInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideMedicalReferralsMedicalInfo/' + countryCode)
    GetCountryGuideGeneralDiseaseAndPreventionContentMedicalInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideGeneralDiseaseAndPreventionContentMedicalInfo/' + countryCode)
    GetCountryGuideMedicalCareMedicalInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideMedicalCareMedicalInfo/' + countryCode)
    GetCountryGuideDiseasesMedicalInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideDiseasesMedicalInfo/' + countryCode)
    GetCountryGuideMedicalSummaryMedicalInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideMedicalSummaryMedicalInfo/' + countryCode)
    //------------------
    getCountryGuideEntryExitInfo = (countryCode) => apiBase.get('/InternationalSos/GetCountryGuideEntryExitInfo/' + countryCode)
    getTailsDetails = (tails) => apiBase.get('/Flight/GetBulkTailDetails/?tails=' + tails)
    getFlightsRouteDetails = (flightIDs) => apiBase.get(`/Flight/GetFlightRoute?ids=${flightIDs}`)
    getFlightsPositionDetails = (flightIDs) => apiBase.get(`/Flight/GetFlightPositions?ids=${flightIDs}`)
    getAircraftDetails = (aircraftType) => apiBase.get(`/Flight/aircraft/types/${aircraftType}`)
    decodeRoute = (origin, route, destination) => apiBase.get(`/Flight/plan-decoder/decode-route?origin=${origin}&route=${route}&destination=${destination}`)
    getAirportRunwayDetails = (ICAO) => apiBase.get(`/Flight/plan-decoder/airports/${ICAO}/runway`)
    getCommunications = (alertID) => apiBase.get('/message/GetCommunications?alertId=' + alertID)
    getUserFlightTrackingInfo = () => apiBase.get('/Flight/GetUserFlightTrackingInfo')
    addUserTail = (data) => apiBase.post('/Tails/AddUserTails', data)
    deleteUserTail = (tailID) => apiBase.delete('/Tails/DeleteUserTail/' + tailID)
    //------------------
    downloadBriefBuilderReport = (reportType, data) => {
        const requestData = new FormData();
        requestData.append("type", reportType);
        requestData.append("data", data);
        requestData.append("authorization", accessToken);
        return apiBase.postFile(config.ReportAPI, requestData)
    }

}

const SecurityCMSAPIService = new ApiService()

export default SecurityCMSAPIService
