import authClient from './modules/oktaAuth'
import { config } from './Config'
import axios from 'axios'
import ClientPortalAPIService from './services/api/ClientPortal/ApiService'

const oktaSignIn = new OktaSignIn({
    baseUrl: config.OktaBaseURL,
    clientId: config.OktaClientID,
    issuer: config.OktaIssuer,
    redirectUri: window.location.origin,
    pkce: true,
    authParams: {
        issuer: config.OktaIssuer,
        scopes: ['openid', 'profile', 'email', 'offline_access']
    },
    colors: {
        brand: '#5FE9D0' // If you want even more customization, you can modify the Sass source files https://github.com/okta/okta-signin-widget#colors
    },
    features: {
        showPasswordToggleOnSignInPage: true
    },
    i18n: {
        'en': {
            'primaryauth.title': 'Please sign in to continue',
            'primaryauth.username.placeholder': 'Email',
            'error.username.required': 'Please enter email',
            'errors.E0000004': 'Invalid email or password'
        }
    }
})

function redirectUserBasedOnHisPermission() {
    ClientPortalAPIService.getUserPermissionList().then(userPermissions => {
        // If the user has permission to luxury yachting, then redirect him to that page no matter what other module access he has
        // It's beacuse it was agreed that for now users in OKTA will not have permission to see Aviation and Maritime at the same time
        if (userPermissions.indexOf('luxury_yachting') > -1 || userPermissions.indexOf('MediTrack') > -1) {
            window.location.href = '/luxury-yachting.html'
        } else {
            // If the user has only one permission and it is a basic permission (which is the basic Aviation permission), then we redirect him 
            // Directly to the Aviation Security Module, no need for him to go to the dashboard with one link only to the Security Module
            if (userPermissions.length === 1 && userPermissions[0] === 'basic') {
                window.location.href = '/app/index.html'
            } else {
                window.location.href = '/dashboard.html'
            }
        }
    })
}

function setTokenAndDetermineUserRedirection(accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    const params = new URLSearchParams(window.location.search)
    const store = params.get('store')
    const redirectUrl = params.get('redirect_url')
    // When the page contains the store and redirect url we know that the user is coming from the eShop and we need to authenticate him and redirect him back
    if (store && redirectUrl) {
        axios.get(`${config.websiteHost}/api/shopify/GetMultipassUrl?store=${store}&redirectUrl=${redirectUrl}`)
            .then(response => {
                if (response.data) {
                    window.location.href = response.data
                } else {
                    window.location.href = '/'
                }
            })
            .catch(error => {
                if (error.status === 403) {
                    window.alert('You tried to authenicate to the system through Luxury Yachting eShop, but being currently authenticated through OKTA with an account that does not have access to Luxury Yachting. Please log out from your existing account and try again.')
                } else {
                    window.alert('There was an error authenticating you to the system through Luxury Yachting eShop, please try again by refreshing the page. If the problem persists, please contact support.')
                }
            })
    } else {
        redirectUserBasedOnHisPermission()
    }
}

function showSignInWidget() {
    // We need to also display the container for the widget which has an inline display: none property
    document.getElementsByClassName('js-login-side-panel')[0].style.removeProperty('display')

    oktaSignIn.showSignInToGetTokens({
        el: '#okta-login-container'
    }).then(tokens => {
        // It would be nice to have a comment on why this is needed and how could we end up here with token, but without access token
        // and why the window.location.reload() was implemented
        if (tokens.accessToken !== null) {
            oktaSignIn.authClient.tokenManager.setTokens(tokens)
            initialiseMixpanel(tokens.idToken.claims.sub, tokens.idToken.claims.email)
            setTokenAndDetermineUserRedirection(tokens.accessToken.value)
        } else {
            window.location.reload()
        }
    }).catch(error => {
        console.error(error)
        window.alert('There was an error loading the Sign In widget, please refresh the page and try again.')
    })
}

function initialiseMixpanel(sub, email) {
    // Mixpanel could be undefined if the user didn't give the consent for tracking cookies
    if (window['mixpanel'] !== undefined) {
        window['mixpanel'].init(config.mixpanelToken, { debug: config.debugMode })
        window['mixpanel'].identify(sub)
        window['mixpanel'].people.set({ 'Client Domain': email.split('@')[1] })
        window['mixpanel'].people.set({ 'domain': window.location.hostname })
        window['mixpanel'].people.unset(['email', 'Email', 'name', 'Name'])
        window['mixpanel'].track('Sign In', { 'domain': window.location.hostname })
    }
}

function handleGettingTokenWithoutPrompt() {
    // We need to get the access token
    authClient.token.getWithoutPrompt()
        .then(response => {
            authClient.tokenManager.setTokens(response.tokens)
            initialiseMixpanel(response.tokens.idToken.claims.sub, response.tokens.idToken.claims.email)
            setTokenAndDetermineUserRedirection(response.tokens.accessToken.accessToken)
        })
        .catch(error => {
            // Can't see how this could fail, but just in case let's log it so our Sentry IO can capture it
            console.error(error)

            // If this happens because login is required, then as a safe mesure let's show them the Sign in widget
            if (err.errorCode === 'login_required') {
                showSignInWidget()
            } else {
                // Otherwise we show some error message to the user
                window.alert('There was an error retrieving your Okta session, please refresh the page and try again.')
            }
        })
}

authClient.session.get()
    .then(session => {
        // We check for the active session, because of the NetJets SSO integration, where all users are already logged in to OKTA
        // And when they come to our web app, they will have the active session, but they will not have the access token
        // If we don't have a session we assume these are all other normal users and we show them the Sign In Widget
        if (session.status === 'ACTIVE') {
            // User already has an active session and attempted to see this login page, we should just redirect him back to the app.
            // But we need to see if we have access the Access Token, if we don't have it, then we need to get it
            // User can have an active session, but no access token, this can happen if he logged to OKTA externally and came to our app
            const accessToken = authClient.getAccessToken()
            if (accessToken !== undefined) {
                const idToken = authClient.getIdToken()

                // We could have access token from Okta from some other access like logging into different envirnoment, but we don't have 
                // a token stored inside our token manager, so we need to get it and set it
                if (idToken === undefined) {
                    handleGettingTokenWithoutPrompt()
                } else {
                    // Otherwise if it's all good, then we redirect the user to the platform, this seem to be the case when user is logged
                    // in with active session and all tokens and just tries to visit the main login page again
                    authClient.token.getUserInfo().then(userInfo => {
                        initialiseMixpanel(userInfo.sub, userInfo.email)
                        setTokenAndDetermineUserRedirection(accessToken)
                    })
                }
            } else {
                handleGettingTokenWithoutPrompt()
            }
        } else {
            showSignInWidget()
        }
    })
    .catch(error => {
        console.error(error)
        window.alert('There was an error retrieving your Okta session, please refresh the page and try again.')
    })
